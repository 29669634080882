<template>
  <v-dialog
      v-model="dialog"
      width="500"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      persistent
  >
    <v-card>
      <v-card-title class="card-header-add font-weight-bold px-3 py-1 createAppointmentDialog-border-bottom">
        <v-row dense class="">
          <v-col class="d-flex align-center justify-start" cols="10">
            <h5 class="text-capitalize white--text font-size15"> {{selectedAppointment.name}}</h5>
          </v-col>
          <v-col class="d-flex align-center justify-end" cols="2">
            <v-icon class="white--text"  size="23" @click="$emit('false')">mdi mdi-close</v-icon>
          </v-col>
        </v-row>
      </v-card-title>
      <div class="pa-5">
        <v-row dense>
          <v-col class="text-left">
            <h6 class="text--black font-size14"> {{$t('start-date')}} : {{ formatDate(selectedAppointment.start) }}</h6>
          </v-col>
          <v-col class="text-right">
            <h6 class="text--black font-size14">{{$t('duration')}} : 15 {{$t('minutes')}}</h6>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col class="text-left d-flex align-center">
            <span class="font-weight-medium font-size14 mr-1">{{$t('patient')}}:</span>
            <span class="font-weight-medium font-size14">{{selectedAppointment.patientData.firstName}} {{selectedAppointment.patientData.lastName}}</span>
          </v-col>
          <v-col class="text-right d-flex align-center justify-end">
            <span class="font-size14 font-weight-medium mr-1">{{$t('doctor')}}:</span>
            <span class="font-size14 font-weight-medium">{{selectedAppointment.doctorData.firstName}} {{selectedAppointment.doctorData.lastName}}</span>
          </v-col>
        </v-row>

        <v-row dense>
          <v-card elevation="3" width="100%" height="200" class="overflow-y-auto">
            <v-col class="d-flex align-center text-left">
              <!--            <h6 class="py-1">{{$t('appointmentNote')}}:</h6>-->
              <h6 class="font-size12-5">{{selectedAppointment.description}}</h6>
            </v-col>
          </v-card>
        </v-row>
        <div class="mt-3 mx-n1" v-if="selectedAppointment.appointmentStatusId !== 1 && roleName === 'DOCTOR'">
          <v-btn block color="primary2" @click.stop="goToRoom(selectedAppointment)">
            <v-icon class="mr-2" small color="white">mdi mdi-phone</v-icon>
            <h6 class="text-capitalize font-size15 white-color">{{$t('telehealthJoin')}}</h6>
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
var Vue = null;
export default {
  props: ['dialog', 'item', 'selectedAppointment'],
  data () {
    return {
      loader: null,
      loading: false,
      loadingReject: false,
      loaderReject: false,
      tab: 0,
      tabs: [{ name: 'Accept' }, { name: 'Reject' }],
      header: 'accept',
      appointmentDate: null,
      appointmentTime: null,
      appointmentPlannedDurationInMin: 15,
      appointmentTitle: '',
      appointmentDescription: '',
      rejectMessage: '',
      valid: false,
      menu1: false,
      menu2: false,
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dateFormatted: null,
      time1: null,
    };
  },
  computed: {
    ...mapState({
      appointmentsForTodayFromRepo: (state) => state.appointments.appointmentsForToday,
      relatedUsers: state => state.hospitals.medicalStaff,
    }),
    ...mapGetters({
      roleName: 'authentication/getRole',
      delay: 'authentication/getDelay',
      pageHeight: 'pageHeight',
    }),
    computedDateFormattedMomentjs () {
      return this.date;
    },
    rules () {
      return [(v) => !!v || 'This field cannot be blank!'];
    },
    rulesForMinutes () {
      if (
          (this.appointmentPlannedDurationInMin < 0 ||
              this.appointmentPlannedDurationInMin > 59) || this.appointmentPlannedDurationInMin === ''
      ) {
        return ['Please choose a number between 0-59'];
      } else return [];
    },
    dateTime () {
      return this.appointmentDate + 'T' + this.appointmentTime + ':00';
    },
  },
  watch: {
    async loader () {
      if (this.loader !== null) {
        const l = this.loader;
        this[l] = !this[l];

        await this.approveRequest()
            .then(() => {
              this[l] = false;
            })
            .catch(() => {
              this[l] = false;
            });

        this.loader = null;
      }
    },
    async loaderReject () {
      if (this.loaderReject !== null) {
        const l = this.loaderReject;
        this[l] = !this[l];

        await this.rejectRequest()
            .then(() => {
              this[l] = false;
            })
            .catch(() => {
              this[l] = false;
            });

        this.loaderReject = null;
      }
    },
  },
  methods: {
    async goToRoom (item) {
      Vue = this;
      Vue.snackStatus = false;
      await navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then(function () {
            Vue.noDevices = false;
          })
          .catch(function (err) {
            Vue.noDevices = true;
            Vue.$store.commit('alerts/add', {
              id: 'checkDevices',
              type: 'error',
              color: 'main_red',
              message: err,
            });
            this.$store.dispatch('alerts/clearWithDelay', this.delay);
          });
      if (Vue.noDevices) {
        Vue = null;
        return;
      }
      Vue = null;
      this.$router.push({
        name: 'VideoRoom',
        params: {
          id: `${item.videoRoomId}`,
          userId: `${item.patientId}`,
          appointmentId: item.appointmentId ? item.appointmentId : item.id,
          token: localStorage.sessionToken,
          needVideoRecording: item.needVideoRecording,
          videotrack: true,
        },
      });
    },
    formatDate (dateString) {
      const date = new Date(dateString);

      const day = date.getDate();
      const month = date.toLocaleString('default', { month: 'short' });
      const year = date.getFullYear();
      const hours = date.getHours();
      const minutes = date.getMinutes();

      return `${day} ${month} ${year} ${hours}:${String(minutes).padStart(2, '0')}`;
    },
    closeDialog () {
      this.dialog = false;
      // this.$emit('close');
    },
    async createAppointment () {
      const body = {
        appointmentRequestId: this.item.appointmentRequestId,
        appointmentDateTime: this.dateTime,
        appointmentPlannedDurationInMin: parseInt(
            this.appointmentPlannedDurationInMin,
        ),
        appointmentTitle: this.appointmentTitle,
        appointmentDescription: this.appointmentDescription,
      };
      await this.$store
          .dispatch('appointments/createAppointment', body)
          .then(async () => {
            await this.markAsRead();
          })
          .catch(() => {});
      this.$emit('false');
      this.loading = false;
    },
    async rejectRequest () {
      this.loading = true;
      const body = {
        appointmentRequestId: this.item.appointmentRequestId,
        approveThis: false,
        responseComment: this.rejectMessage,
      };
      await this.$store
          .dispatch('appointmentrequests/approveRequest', body)
          .then(async () => {
            await this.markAsRead();
          })
          .catch(() => {});
      this.$emit('false');
      this.loading = false;
    },
    async approveRequest () {
      this.loading = true;
      const body = {
        appointmentRequestId: this.item.appointmentRequestId,
        approveThis: true,
        responseComment: '',
      };
      await this.$store
          .dispatch('appointmentrequests/approveRequest', body)
          .then(async () => {
            await this.createAppointment();
          })
          .catch(() => {});
    },
    async markAsRead () {
      await this.$store.dispatch(
          'notifications/markAsRead',
          this.item.NotificationId,
      );
    },
    getMinDate () {
      const date = new Date();
      return date.toISOString().split('T')[0];
    },
    getMinTime () {
      var date = new Date();
      if (this.appointmentDate === this.getMinDate()) {
        return `${date.getHours() + ':' + date.getMinutes()}`;
      }
    },
  },
};
</script>
