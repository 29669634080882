<template>
  <v-row class="d-flex justify-center my-2 mx-2 px-2 background-white flex-row-reverse elevation-2">
    <v-col class="d-flex" cols="3" v-for="(item, index) in items" :key="index">
      <v-row dense v-if="item.id === 4" class="d-flex align-center" justify="space-around">
        <span class="font-size15 font-weight-bold">{{$t('total-office-score')}}</span>
        <v-progress-circular
            :rotate="-90"
            :size="60"
            :width="4"
            :indeterminate="loading"
            :value="loading ? 0 : (reportsRepo.totalAverageScore * 100)"
            :color="getColor(calc(item))">
          <span v-if="!loading">{{ (reportsRepo.totalAverageScore * 100).toFixed(1) }}%</span>
        </v-progress-circular>
      </v-row>
      <v-row  dense align="center" justify="space-around" v-else>
        <span class="font-size15 font-weight-bold">{{item.text}}</span>
        <v-progress-circular
            :rotate="-90"
            :size="60"
            :width="4"
            :indeterminate="loading"
            :value="loading ? 0 : calc(item)"
            :color="getColor(calc(item))">
          <span v-if="!loading" style="font-size:16px;">{{ calc(item).toFixed(1) }}%</span>
        </v-progress-circular>
      </v-row>
      <v-divider v-if="index !== 0" vertical class="ml-6"></v-divider>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  data () {
    return {
      localLoading: true, // local data to manage loading state
    };
  },
  computed: {
    ...mapState({
      reportsRepo: (state) => state.reportsRepo.allReports,
      notifications: (state) => state.notifications.allNotifications,
      abnormalNotifications: (state) => state.notifications.abnormalMeasurements,
      missed: (state) => state.notifications.missMeasurements,
      chatNotifications: (state) => state.notifications.chatNotifications,
    }),
    ...mapGetters({
      roleName: 'authentication/getRole',
      globalLoading: 'notifications/loading', // loading state from Vuex
    }),
    items () {
      if (this.roleName === 'DOCTOR' || this.roleName === 'NURSE') {
        return [
          {
            text: this.$t('all-notifications'),
            read: this.notifications.filter((a) => a.IsRead).length,
            all: this.notifications.length,
            id: 1,
          },
          {
            text: this.$t('Chat-Notifications'),
            read: this.chatNotifications.filter((a) => a.IsRead).length,
            all: this.chatNotifications.length,
            id: 2,
          },
          {
            text: this.$t('abnormal-notifications'),
            read: this.abnormalNotifications.filter((a) => a.IsRead).length,
            all: this.abnormalNotifications.length,
            id: 3,
          },
          {
            text: this.$t('missed-notifications'),
            read: this.missed.filter((a) => a.IsRead).length,
            all: this.missed.length,
            id: 4,
          },
        ];
      }
      return [];
    },
    loading () {
      return this.globalLoading || this.localLoading;
    },
  },
  watch: {
    items: {
      handler (newItems) {
        if (newItems.length > 0) {
          this.localLoading = false;
        } else {
          this.localLoading = true;
        }
      },
      deep: true,
      immediate: true,
    },
    globalLoading: {
      handler (newVal) {
        this.localLoading = newVal;
      },
      immediate: true,
    },
  },
  created () {
    // this.getMyNotifications();
  },
  methods: {
    // async getMyNotifications () {
    //   await this.$store.dispatch('notifications/ALL_NOTIFICATIONS', this.$cookies.get('datafilter'));
    // },
    calc (item) {
      if (item.all === 0) {
        return 0;
      }
      return Math.round((item.read / item.all) * 100);
    },
    getColor (percentage) {
      if (percentage >= 80) {
        return 'green';
      } else if (percentage >= 60) {
        return 'orange';
      } else {
        return 'red';
      }
    },
  },
};
</script>
