<template>
  <div class="overflow-y-hidden">
    <DashboardCards/>
    <v-responsive :height="pageHeight(167)">
      <v-row>
        <v-col>
          <DashboardPatients/>
        </v-col>
        <v-col class="px-0 mx-0">
          <DashboardNotifications/>
        </v-col>
        <v-col>
          <DashboardAppointments/>
        </v-col>
      </v-row>
    </v-responsive>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import DashboardCards from '@/views/Client/components/DashboardComponents/DashboardCards.vue';
import DashboardNotifications from '@/views/Client/components/DashboardComponents/DashboardNotifications.vue';
import DashboardPatients from '@/views/Client/components/DashboardComponents/DashboardPatients.vue';
import DashboardAppointments from '@/views/Client/components/DashboardComponents/DashboardAppointments.vue';
export default {
	components: { DashboardPatients, DashboardCards, DashboardNotifications, DashboardAppointments },
	computed: {
		...mapGetters({ pageHeight: 'pageHeight' }),
	},
};
</script>
