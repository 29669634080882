<template>
<div>
  <h6 class="mt-2 ml-4 font-size15 calendar-text" >{{$t("appointments")}}</h6>
  <v-btn  @click="openAppointmentsDialog" color="primary2" class="floating-button" fab >
  <v-icon color="white" class="mdi mdi-plus"></v-icon></v-btn>
  <Calendar2 ref="calendarComponent"></Calendar2>
  <NewAppointmentDialog @close="closeAppointmentsDialog" v-if="createDialog" :dialog="createDialog" @appointmentSaved="refreshCalendar"/>
</div>
</template>
<script>
import NewAppointmentDialog from '@/views/dialogs/NewAppointmentDialog.vue';
import Calendar2 from '@/views/Client/components/Calendar2';
export default {
	components: { NewAppointmentDialog, Calendar2 },
	data () {
		return {
			dialog: false,
			createDialog: false,
		};
	},
  methods: {
    refreshCalendar () {
        this.$refs.calendarComponent.initialize();
    },
    openAppointmentsDialog () {
      this.createDialog = true;
    },
    closeAppointmentsDialog () {
      this.createDialog = false;
    },
  },
};
</script>
