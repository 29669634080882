<template>
  <v-card elevation="0">
    <v-card-title class="d-flex justify-space-between pt-3 pb-2 mt-0 px-0" >
      <v-btn class="table-create-button border-radius50" color="primary" @click="setToday">
        {{ $t("today") }}
      </v-btn>
      <v-toolbar-title v-if="$refs.calendar">
        <v-btn fab text small color="primary2" @click="prev">
          <v-icon>
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <span class="capitalize-text">{{ $refs.calendar.title }}</span>
        <v-btn fab text small color="primary2" @click="next">
          <v-icon>
            mdi-chevron-right
          </v-icon>
        </v-btn>
      </v-toolbar-title>
      <v-menu :rounded="true" bottom right offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="table-button-cancel" v-bind="attrs" v-on="on">
            <span>{{ types[type] }}</span>
            <v-icon right>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="type = 'day'">
            <v-list-item-title>{{ $t('day') }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="type = 'week'">
            <v-list-item-title>{{ $t('week') }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="type = 'month'">
            <v-list-item-title>{{ $t('month') }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="type = '4day'">
            <v-list-item-title>{{ $t('4days') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-title>
    <v-card elevation="0">
      <v-card-text class="px-0" style="position:relative;">
        <v-progress-circular style="position:absolute;top:50%;left:50%; z-index:1" class="mx-2 mt-n1" v-if="loading" :indeterminate="loading"  color="primary"></v-progress-circular>
        <v-sheet height="20%" :class="userRole === 'DOCTOR' ? 'mb-0' : 'mb-n16'" >
          <v-calendar
              :interval-height="$vuetify.breakpoint.height <= 800 ? '15': '25'"
              class="mt-0 border-radius-light-gray"
              ref="calendar"
              v-model="focus"
              :locale="this.$i18n.locale === 'en' ? 'en' : 'es' "
              :events="events"
              :event-color="getEventColor"
              :type="type"
              @click:event="showEvent"
              @click:more="viewDay"
              @click:date="viewDay"
              @change="getDoctorAppointments(doctor, doctorId)"
          ></v-calendar>
          <v-menu
              v-model="selectedOpen"
              :close-on-content-click="false"
              :activator="selectedElement"
              offset-x>
            <v-card
                color="grey lighten-4"
                min-width="350px"
                max-width="350px"
                flat>
              <v-card-title class="pa-0">
                <v-toolbar :color="selectedEvent.color" dark>
                  <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                </v-toolbar>
              </v-card-title>
              <v-card-text class="pa-0">
                <v-row dense>
                  <v-col cols="12" class="pt-4 px-3 ma-0">
                    <v-text-field
                        v-model="selectedEvent.name"
                        :label="$t('appointment-title')"
                        outlined
                        hide-details
                        dense
                        filled
                        class="border-radius12"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" class="ma-0 px-3">
                    <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        title=""
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="appointmentDate"
                            :label="$t('appointment-date')"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            hide-details
                            outlined
                            dense
                            filled
                            class="border-radius12"
                        >

                        </v-text-field>
                      </template>
                      <v-date-picker
                          v-model="appointmentDate"
                          no-title scrollable
                          :min="getMinDate()"
                          @change="menu2 = false"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" class="ma-0 px-3">
                    <v-menu
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="appointmentTime"
                        transition="scale-transition"
                        offset-y max-width="290px"
                        min-width="290px"
                        :title="false"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="appointmentTime"
                            :label="$t('appointment-time')"
                            prepend-inner-icon="mdi-clock-outline"
                            readonly
                            hide-details
                            v-bind="attrs"
                            v-on="on"
                            outlined
                            dense filled
                            class="border-radius12"
                        >

                        </v-text-field>
                      </template>
                      <v-time-picker
                          v-if="menu1"
                          v-model="appointmentTime"
                          :min="getMinTime()"
                          full-width
                          @click:minute="$refs.menu1.save(appointmentTime)"
                          no-title format="ampm"
                      >

                      </v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" class="ma-0 px-3">
                    <v-textarea
                        v-model="selectedEvent.description"
                        :label="$t('description')"
                        outlined
                        dense
                        no-resize
                        hide-details
                        filled
                        class="border-radius12"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text v-if="selectedEvent.details">
                <span v-html="selectedEvent.details"></span>
              </v-card-text>
              <v-card-actions>
                <v-row dense>
                  <v-col cols="6">
                    <v-btn
                        outlined
                        block
                        elevation="0"
                        @click="selectedOpen = false"
                    >
                      {{ $t("cancel") }}
                    </v-btn>
                  </v-col>
                  <v-col cols="6">
                    <v-btn
                        elevation="0"
                        block
                        :color="selectedEvent.color"
                        @click="saveChanges"
                    >
                      {{ $t("save") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-sheet>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
// import { formatterFilter } from '@/utils/luxon-formater';

export default {
	props: ['doctor'],
	data: () => ({
		loading: false,
		doctorId: null,
		focus: '',
		menu1: false,
		menu2: false,
		appointmentDescription: '',
		appointmentTitle: '',
		appointmentDate: '',
		appointmentTime: '',
		type: 'week',
		types: {
			month: 'Month',
			week: 'Week',
			day: 'Day',
			'4day': '4 Days',
		},
		selectedEvent: {},
		selectedElement: null,
		selectedOpen: false,
		events: [],
		colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
	}),
	computed: {
		...mapState({
      ...mapGetters({ roleName: 'authentication/getRole',	pageHeight: 'pageHeight', userRole: 'authentication/getRole' }),
			loggedUser: state => state.authentication.userData,
		}),
	},
	watch: {
		async doctor (val) {
			await this.getDoctorAppointments(val);
		},
	},
	async mounted () {
		this.labelLanguage();
		this.$refs.calendar.checkChange();
		if (this.loggedUser.roleId === 3) {
			this.doctorId = this.loggedUser.id;
			await this.getDoctorAppointments(undefined, this.loggedUser.id);
		}
	},
	methods: {
		labelLanguage () {
			this.types.month = this.$t('month');
			this.types.week = this.$t('week');
			this.types.day = this.$t('day');
		},
		viewDay ({ date }) {
			this.focus = date;
			this.type = 'day';
		},
		getEventColor (event) {
			return event.color;
		},
		setToday () {
			this.focus = '';
		},
		getMinDate () {
			const date = new Date();
			return date.toISOString().split('T')[0];
		},
		getMinTime () {
			var date = new Date();
			if (this.appointmentDate === this.getMinDate()) {
				return `${date.getHours() + ':' + date.getMinutes()}`;
			}
		},
		prev () {
			this.$refs.calendar.prev();
		},
		saveChanges () {
			const body = {
				appointmentId: this.selectedEvent.appointmentId,
				appointmentDateTime: this.appointmentDate.concat('T').concat(this.appointmentTime),
				appointmentDurationInMin: 0,
				appointmentTitle: this.selectedEvent.name,
				appointmentDescription: this.selectedEvent.description,
			};
			this.$store.dispatch('appointments/updateAppointments', body);
		},
		next () {
			this.$refs.calendar.next();
		},
		showEvent ({ nativeEvent, event }) {
			const open = () => {
				this.selectedEvent = event;
				this.selectedElement = nativeEvent.target;
        // requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true));
        this.selectedOpen = true;
			};

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    async getDoctorAppointments (doctor, doctorId) {
      if (doctor || doctorId) {
        this.loading = true;
        try {
          const events = [];
          const appointments = await this.$store.dispatch('appointments/getAppointmentsForDoctor', doctor || doctorId);

          if (appointments.length > 0) {
            appointments.forEach(appointment => {
              const first = new Date(appointment.appointmentDateTime);
              const secondTimestamp = appointment.appointmentPlannedDurationInMin * 60000;
              const second = new Date(first.getTime() + secondTimestamp);
              events.push({
                name: appointment.appointmentTitle,
                description: appointment.appointmentDescription,
                // date: formatterFilter(new Date(appointment.appointmentDateTime).toISOString(), 'date'),
                // time: formatterFilter(new Date(appointment.appointmentDateTime).toISOString(), 'time'),
                start: new Date(appointment.appointmentDateTime),
                end: second,
                color: this.colors[this.rnd(0, this.colors.length - 1)],
                timed: true,
                appointmentId: appointment.appointmentId,
                appointmentStatusId: appointment.appointmentStatusId,
                appointmentType: appointment.appointmentType,
              });
            });

            // Filter out appointments with appointmentType as null
            this.events = events.filter(appointment => appointment.appointmentType === null);
          } else {
            // If no appointments are found, set events to an empty array
            this.events = [];
          }
        } catch (error) {
          this.events = []; // Optionally handle errors by setting events to an empty array
        } finally {
          this.loading = false; // Ensure loading is set to false whether data is found or an error occurs
        }

        return;
      }

      // If no doctor or doctorId is provided, clear the events and set loading to false
      this.events = [];
      this.loading = false;
    },
		rnd (a, b) {
			return Math.floor((b - a + 1) * Math.random()) + a;
		},
	},
};
</script>
