<template>
  <div>
    <div style="position:absolute; font-size:27px;  z-index:1" class="mt-9 ml-10">
      <div @click="prev" style="border:1px solid #ccc; width:35px; height:35px; border-radius:5px; cursor:pointer;">
        <v-icon  color="chips" class="mt-n2" small>
          mdi-chevron-left
        </v-icon>
      </div>
    </div>
    <div style="position:absolute; z-index:1; right:50px;" class="mt-8">
      <div @click="next" style="border:1px solid #ccc; width:35px; height:35px; border-radius:5px; cursor:pointer;" class="mr-2">
        <v-icon color="chips" small class="mt-2">
          mdi-chevron-right
        </v-icon>
      </div>
    </div>
      <v-progress-linear :indeterminate="loading" v-if="loading" style="position:absolute; left:67%; top:77px; right:-20%"></v-progress-linear>

    <v-calendar
        ref="calendar"
        v-model="focus"
        color="primary"
        :style="{ height: pageHeight(164) }"
        :events="events"
        :locale="$i18n.locale === 'en' ? 'en' : 'es' "
        :event-color="getEventColor"
        :type="type"
        class="mr-4 elevation-2 text-left"
        style="border-radius:6px;"
        @click:event="showEvent"
        @click:more="viewDay"
        @click:date="viewDay"
        @change="getDoctorAppointments"
        >
      <template v-slot:event="data">
        <div class="d-flex justify-space-between">
          <strong>{{data.eventParsed.input.doctorData.firstName + ' ' + data.eventParsed.input.doctorData.lastName}}</strong>
          <strong>{{data.eventParsed.input.patientData.firstName + ' ' + data.eventParsed.input.patientData.lastName}}</strong>
          <strong>{{ formatTime(data.eventParsed.input.start)}} - {{ formatTime(data.eventParsed.input.end)}}</strong>
          <!--          <strong class="mr-2">{{ data.eventParsed.input.name }}</strong>-->
        </div>
      </template>
    </v-calendar>
<!--    <v-menu :close-on-content-click="false" :activator="selectedElement " offset-x>-->
      <CreateAppointmentDialog :dialog="dialogVisible" v-if="dialogVisible" :selected-appointment="selectedAppointment" @false="dialogVisible = false"></CreateAppointmentDialog>
<!--    </v-menu>-->
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import CreateAppointmentDialog from '@/views/dialogs/CreateAppointmentDialog';
// import { formatterFilter } from '@/utils/luxon-formater';
export default {
  components: { CreateAppointmentDialog },
  props: ['doctor'],
	data: () => ({
		doctorId: null,
    ready: false,
    newEvents: [],
    loading: false,
		focus: '',
		menu1: false,
		menu2: false,
    dialogVisible: false,
    selectedAppointment: null,
		appointmentDescription: '',
		appointmentTitle: '',
		appointmentDate: '',
		appointmentTime: '',
		type: 'day',
		typeToLabel: {
			month: 'Month',
			week: 'Week',
			day: 'Day',
			'4day': '4 Days',
		},
		selectedEvent: {},
		selectedElement: null,
		events: [],
		colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
		names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
	}),
	computed: {
		...mapState({
			loggedUser: state => state.authentication.userData,
      appointmentsForTodayFromRepo: (state) => state.appointments.appointmentsForToday,
		}),
    ...mapGetters({	pageHeight: 'pageHeight', roleName: 'authentication/getRole', hospitalId: 'authentication/getHospitalId', selectedHospital: 'hospitals/getSelectedHospital' }),
    cal () {
      return this.ready ? this.$refs.calendar : null;
    },
	},
	async mounted () {
    this.ready = true;
    this.scrollToTime();
    this.updateTime();

		this.labelLanguage();
		this.$refs.calendar.checkChange();
		if (this.loggedUser.roleId === 3) {
			this.doctorId = this.loggedUser.id;
		}
    await this.initialize();
	},
	methods: {
    formatTime (dateString) {
      const date = new Date(dateString); // Convert the string to a Date object
      return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
    },
    async getAppointmentsForHospitalForDate () {
      this.loading = true;
      const body = {
        hospitalId: this.hospitalId,
        paramDate: this.$refs.calendar.value,
      };
      this.$store.dispatch('appointments/getAppointmentsForHospitalForDate', body).then(res => {
        this.loading = false;
        const newEvents = res;
        const events = [];
        newEvents.forEach(appointment => {
          const first = new Date(appointment.appointmentDateTime);
          const secondTimestamp = appointment.appointmentPlannedDurationInMin * 60000;
          const second = new Date(first.getTime() + secondTimestamp);
          events.push({
            name: appointment.appointmentTitle,
            description: appointment.appointmentDescription,
            start: first,
            end: second,
            color: this.colors[this.rnd(0, this.colors.length - 1)],
            timed: true,
            patientData: appointment.patientData,
            patientId: appointment.patientId,
            doctorData: appointment.doctorData,
            appointmentId: appointment.appointmentId,
            appointmentStatusId: appointment.appointmentStatusId,
            videoRoomId: appointment.videoRoomId,
            appointmentType: appointment.appointmentType,
          });
          this.events = events;
          this.events = events.filter(appointment => appointment.appointmentType === null);
        });
      });
    },
    async initialize () {
      if (this.roleName === 'DOCTOR') {
        this.loading = true;
        await this.$store.dispatch('appointments/getAppointmentsForToday', this.loggedUser.id).then(res => {
          this.getDoctorAppointments();
        });
      }
      if (this.roleName === 'NURSE') {
        await this.$store.dispatch('appointments/getAppointmentsByHospitalId', this.loggedUser?.hospitalAssignedData?.id).then(res => {
          this.getDoctorAppointments();
        });
      }
    },
    getCurrentTime () {
      return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0;
    },
    scrollToTime () {
      const time = this.getCurrentTime();
      const first = Math.max(0, time - (time % 30) - 30);

      this.cal.scrollToTime(first);
    },
    updateTime () {
      setInterval(() => this.cal.updateTimes(), 60 * 1000);
    },
		labelLanguage () {
			this.typeToLabel.month = this.$t('month');
			this.typeToLabel.week = this.$t('week');
			this.typeToLabel.day = this.$t('day');
		},
		viewDay ({ date }) {
			this.focus = date;
			this.type = 'day';
    },
		getEventColor (event) {
			return event.color;
		},
    prev () {
      this.$refs.calendar.prev();
      setTimeout(() => {
        this.getAppointmentsForHospitalForDate();
      }, 0);
    },
    next () {
      this.$refs.calendar.next();
      setTimeout(() => {
        this.getAppointmentsForHospitalForDate();
      }, 0);
    },
    showEvent ({ nativeEvent, event }) {
      this.dialogVisible = true;
      this.selectedEvent = event;
      this.selectedElement = nativeEvent.target;
      this.selectedAppointment = event;
      nativeEvent.stopPropagation();
    },

    async getDoctorAppointments () {
      if (this.appointmentsForTodayFromRepo) { // Add a null check
        this.loading = false;
        const events = [];
        this.appointmentsForTodayFromRepo.forEach(appointment => {
          const first = new Date(appointment.appointmentDateTime);
            const secondTimestamp = appointment.appointmentPlannedDurationInMin * 60000;
            const second = new Date(first.getTime() + secondTimestamp);
            events.push({
              name: appointment.appointmentTitle,
              description: appointment.appointmentDescription,
              start: first,
              end: second,
              color: this.colors[this.rnd(0, this.colors.length - 1)],
              timed: true,
              patientData: appointment.patientData,
              doctorData: appointment.doctorData,
              patientId: appointment.patientId,
              appointmentId: appointment.appointmentId,
              appointmentStatusId: appointment.appointmentStatusId,
              videoRoomId: appointment.videoRoomId,
              appointmentType: appointment.appointmentType,
            });
        });
        this.events = events;
        this.events = events.filter(appointment => appointment.appointmentType === null);
      }
    },

    rnd (a, b) {
			return Math.floor((b - a + 1) * Math.random()) + a;
		},
	},
};
</script>
<style>
.theme--light.v-calendar-daily .v-calendar-daily__intervals-head{
  display:none;
}
.v-calendar-daily_head-day-label{
  padding-bottom:20px;
}
.v-calendar .v-event-timed{
  padding:20px !important;
}
</style>
